import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo
} from '../edliy_utils-fractions';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-5, 17, 15, -3],
        keepaspectratio: true, axis:false, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});

    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=17;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.polygon.highlight=false;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeLogo(brd1);
    placeTitle(brd1, 'Area of a Triangle', '(Find the Area of Triangle ABC)');
/////////////////////
    var analytics = placeShuffle(brd1);
    analytics.setLabel('Tap to Shuffle');
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeTest(brd1);
    erase.setLabel('Tap to Test Your Answer');
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////
    var i = 0;
    var Pt0 = placePoint(brd1,  1., 0, 3, 'red', 'red');
    placeLabel(brd1, Pt0, 'B',-15, -15);
    Pt0.setAttribute({snapToGrid:true});
    var Pt1 = placePoint(brd1,  5., 6, 3, 'red', 'red');
    placeLabel(brd1, Pt1, 'A', -15, 15);
    Pt1.setAttribute({snapToGrid:true});
    var Pt2 = placePoint(brd1,  9., 0, 3, 'red', 'red');
    placeLabel(brd1, Pt2, 'C', 15, -15);
    Pt2.setAttribute({snapToGrid:true});

    var Horz = placeDash(brd1, [()=>Pt0.X(), ()=>Pt0.Y()], [()=>Pt2.X(), ()=>Pt2.Y()], 2, 'black');
    Horz.setAttribute({visible:false});
    var Vert =placeDash(brd1, [()=>Pt0.X(), ()=>Pt0.Y()], [()=>Pt1.X(), ()=>Pt1.Y()], 2, 'black');
    Vert.setAttribute({visible:false});
  //  brd1.create('perpendicularsegment', [Pt1, Horz], {strokeWidth:1, dash:1});
    var PtP = brd1.create('perpendicularpoint', [Pt1, Horz], {size:0, name:''});
    placeDash(brd1, [()=>Pt1.X(), ()=>Pt1.Y()], [()=>PtP.X(), ()=>PtP.Y()], 2, 'black');
    placeDash(brd1, [()=>Pt2.X(), ()=>Pt2.Y()], [()=>PtP.X(), ()=>PtP.Y()], 2, 'black');
    var height = placeTapeVert(brd1, [()=>Pt1.X(), ()=>Pt1.Y()], [()=>PtP.X(), ()=>PtP.Y()], 'h');
    height.setAttribute({dash:1, strokeWidth:2,  point1:{visible:false}, point2:{visible:false}});
    var base = placeTapeHori(brd1, [()=>Pt0.X(), ()=>Pt0.Y()], [()=>Pt2.X(), ()=>Pt2.Y()], 'b');
    base.setAttribute({dash:1, strokeWidth:2, point1:{visible:false}, point2:{visible:false}});
    var shape =placeTri(brd1, [()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt0.X(), ()=>Pt0.Y()], [()=>Pt2.X(), ()=>Pt2.Y()],'blue');
    shape.setAttribute({fillOpacity:function(){if(i==2){return 0.}else{return 0.5}}, borders:{visible:true, dash:1, strokeColor:'black', strokeWidth:2}});
/////////////////////////////////////////////////////////////////////////
    var movePts= function(){
      Pt1.moveTo([5, 6+ Math.round(4*Math.random())], 100);
      Pt2.moveTo([9+Math.round(4*Math.random()), 0], 100);
    }
    analytics.on('down', function(){
                  movePts();
                  wrong.setAttribute({visible:false});
                  right.setAttribute({visible:false});
                  query.setAttribute({visible:true});
                  clearInputFields(brd1);
    });

    var line1 = placeBoldText(brd1, 5, 13.5, ()=>'b = ' +(base.Value()).toFixed(1));
    //line1.setAttribute({visible:()=>i>0});

    var line2 = placeBoldText(brd1, 5, 12.5, ()=>'h = ' +(height.Value()).toFixed(1));
    //line2.setAttribute({visible:()=>i>0});

    var line2 = placeBoldText(brd1, 3.0, 11.5, ()=>'Area of Triangle ABC =');
    line2.setAttribute({alignX:'left'});

    //placeLeftText(brd1, 8, 11.5, '?');
    //line2.setAttribute({visible:()=>i>0});
    var Inp =placeInput(brd1, 6.,11.5, "");
    var query = placeImage(brd1, '/assets/query.svg', 8.25, 11.25, 0.5,  0);
    var right = placeImage(brd1, '/assets/check.svg', 8.75, 11, 1.,  0);
    var wrong = placeImage(brd1, '/assets/cross.svg', 8.75, 11, 1.,  0);
    right.setAttribute({visible:false});
    wrong.setAttribute({visible:false});

    erase.on('down', function(){
      if( (height.Value()*base.Value())/2. == Inp.Value())
      {right.setAttribute({visible:true});
       wrong.setAttribute({visible:false});
       query.setAttribute({visible:false});
    }
      else {
      wrong.setAttribute({visible:true});
      right.setAttribute({visible:false});
      query.setAttribute({visible:false});
    }
    });

///////////////////////////////////////////////////////////////////////
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
